<template>
  <v-card>
    <v-card-title>
      <v-btn
        id="btn1"
        v-can="'stage.create'"
        color="primary"
        dark
        @click="dialogModal(true, 'create')"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      class="dd"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>
      <template v-slot:header.actions="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div id="actions" class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <!--<menu-item :label="$('students')" color="primary" icon="mdi-account-school" @click="$router.push({name: 'student', params: { stageId: item.id }})"></menu-item>-->
              <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
              <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <action-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></action-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import ActionDialog from './dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import MenuItem from '@/components/menu/menuItem.vue';

export default {
  name: 'Stages',
  components: { DatatableHeader, ActionDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.stage',
    filters: false,
    items: [],
    total: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filters,
      })
      const response = await axios.get(`/stages/dt?${query}`);
      this.items = response.data.data.data
      this.total = response.data.data.total
      this.loading = false
    },
    downloadPDF() {
      window.print()
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
